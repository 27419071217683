body, * {
    font-family: "Open Sans", "Almarai", sans-serif;
}

.content {
    width: calc(100% - 305px);
    margin-top: 62px;
}

@media (max-width: 1199px) {
    .content {
        width: 100%;
    }
}

@media (max-width: 350px) {
    .menu-lang i {
        display: none;
    }
}

.sidebar, .menu, .bg-normal-black {
    background-color: #313944;
}

.bg-light-black {
    background-color: #3f4a59;
}

.dashboard h5 {
    color: #081273;
}

.menu-social i {
    color: #313944;
}

.sidebar-nav li:hover, .sidebar-admin {
    background-color: #252c35;
}

.bg-orange {
    background-color: #ffb35e;
}

.text-orange {
    color: #ffb35e;
}

.bg-green {
    background-color: #58deb6;
}

.text-green {
    color: #58deb6;
}

.bg-light-blue {
    background-color: #62a7ff;
}

.text-light-blue {
    color: #62a7ff;
}

body, .bg-gray {
    background-color: #f1f1f1;
}

.bg-nile {
    background-color: #2684b5;
}

.text-nile {
    color: #2684b5;
}

.bg-rose {
    background-color: #e16298;
}

.text-rose {
    color: #e16298;
}

.bg-red {
    background-color: #ba2556;
}

.bg-brown {
    background-color: #b06039;
}

.bg-purple {
    background-color: #ad58de;
}

.sidebar-nav i, .menu-lang i {
    font-size: 18px;
}

.menu-social i, .menu-setting i, .menu-logo i {
    font-size: 18px;
    cursor: pointer;
}

.dashboard-case, .dashboard-case_weekly, .section h3, .popup {
    border-radius: 25px;
}
.popup i {
    font-size: 25px !important;
}
.section-table-title {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.section-table .btn-group .btn:first-of-type, .section-table thead tr:first-of-type th:first-of-type {
    border-top-left-radius: 25px;
}

.section-table .btn-group .btn:last-of-type, .section-table thead tr:first-of-type th:last-of-type {
    border-top-right-radius: 25px;
}

.section-table .btn-group .btn:first-of-type, .section-table tbody tr:last-of-type th {
    border-bottom-left-radius: 25px;
}

.section-table .btn-group .btn:last-of-type, .section-table tbody tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 25px;
}

.section-table-container, .section-table, .bottom-rounded {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.menu {
    left: 0;
    right: 0;
}

.menu {
    z-index: 1;
}

.section-circle, .sidebar-admin img {
    width: 85px;
    height: 85px;
}

.menu-setting span {
    margin: -8px 0 0 -8px;
}

.menu-social li {
    border-radius: 30px;
    width: 30px;
    height: 30px;
}

.menu-social a {
    line-height: 0;
}

.sidebar {
    width: 305px;
    top: 62px;
    left: 0;
    bottom: 0;
}

.sidebar-nav li a.active {
    background-color: #3f4a59;
}

.section-table-title img {
    height: 50px;
}

.section-table table {
    font-size: 12px;
}

.section-table i {
    font-size: 18px;
}

.section-list-room div {
    width: 105px;
    height: 105px;
}

.section-list-room small {
    font-size: 13px;
    font-weight: 100;
    margin-top: -40px !important;
    width: 33px;
}

.section-list-notify div div {
    width: 40px;
    height: 40px;
}

.section-link img {
    height: 35px;
}

.section-payment-list {
    width: 240px;
}

.section-payment-list li {
    width: 80px;
}

.section-payment-list li small:first-of-type {
    width: 20px;
    height: 20px;
}

.section-payment-list hr {
    left: 30px;
    right: 30px;
    top: 9px;
}

.table .border-white.py-3.bg-normal-black.top-rounded {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
}

.rtl body {
    direction: rtl;
}

.rtl ul {
    padding-left: 0;
    padding-right: 0;
}

.rtl .menu-setting span {
    margin: -8px -8px 0 0;
}

.rtl .text-left {
    text-align: right !important;
}

.rtl .text-right {
    text-align: left !important;
}

.rtl .mr-auto {
    margin-left: auto !important;
    margin-right: unset !important;
}

.rtl .mr-2 {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
}

.rtl .ml-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
}

.rtl .ml-auto {
    margin-right: auto !important;
    margin-left: unset !important;
}

.rtl .sidebar {
    right: 0;
    left: auto;
}

.rtl .sidebar-nav i {
    float: right;
}

.rtl .form-check {
    padding-left: 0 !important;
    padding-right: 1.25rem;
}

.rtl .form-check-input {
    margin-left: 0 !important;
    margin-right: -1.25rem !important;
}

.rtl .section-table .btn-group .btn:last-of-type {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 0;
    border-top-left-radius: 25px !important;
}

.rtl .section-table .btn-group .btn:first-of-type {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}

.rtl .section-table .btn-group .btn:last-of-type, .rtl .section-table thead tr:first-of-type th:last-of-type {
    border-top-left-radius: 25px;
    border-top-right-radius: 0 !important;
}

.rtl .section-table .btn-group .btn:first-of-type, .rtl .section-table thead tr:first-of-type th:first-of-type {
    border-top-right-radius: 25px;
    border-top-left-radius: 0 !important;
}

.rtl .section-table tbody tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 25px !important;
}

.rtl .section-table tbody tr:last-of-type th {
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 0;
}

@media (min-width: 1199px) {
    .rtl .content {
        margin-right: 305px;
    }
}

@media (min-width: 992px) {
    .rtl .ml-lg-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    .rtl .mr-lg-auto {
        margin-left: auto !important;
        margin-right: unset !important;
    }

    .rtl .offset-lg-5 {
        margin-right: 41.666667%;
        margin-left: 0;
    }

    .rtl .text-lg-left {
        text-align: right !important;
    }
}

@media (min-width: 768px) {
    .rtl .ml-md-auto {
        margin-right: auto !important;
        margin-left: unset !important;
    }

    .rtl .text-md-left {
        text-align: right !important;
    }
}

@media (min-width: 576px) {
    .rtl .mr-sm-2 {
        margin-left: 0.5rem !important;
        margin-right: 0;
    }
}

/*# sourceMappingURL=app.css.map */
